import { defineStore } from "pinia";

export const useLeftPanelStore = defineStore("LeftPanelStore", () => {
  if (process.server) return;

  const isLeftPanelExpanded = ref(true);
  const toggleLeftPanel = () => {
    isLeftPanelExpanded.value = !isLeftPanelExpanded.value;
  };

  const showLeftPanelAlert = ref(true);
  const closeLeftPanelAlert = () => {
    showLeftPanelAlert.value = !showLeftPanelAlert;
  };

  const activeTab = ref();
  const updateActiveTab = (tab) => {
    activeTab.value = tab;
  };

  const activeSubMenuTab = ref();
  const updateSubMenuActiveTab = (tab) => {
    activeSubMenuTab.value = tab;
  };

  return {
    showLeftPanelAlert,
    closeLeftPanelAlert,

    // Is Left Panel Expanded
    isLeftPanelExpanded,
    toggleLeftPanel,

    activeTab,
    updateActiveTab,

    activeSubMenuTab,
    updateSubMenuActiveTab,
  };
});
