import { defineStore } from "pinia";

export const useDashboardNavStore = defineStore("DashboardNavStore", () => {
  if (process.server) return;

  const header = ref();
  function updateHeader(value) {
    header.value = value;
  }

  const dash_nav_search_input = ref();

  return {
    header,
    updateHeader,

    dash_nav_search_input,
  };
});
